import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText } from '@ionic/angular/standalone';
import Swal from 'sweetalert2';
import { Producto } from '../../Models/Productos';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';


declare var KueskipayAdvertising: any;

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    IonRow,
    IonCol,
    IonCard,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonGrid,
    IonLabel,
    IonItem,
    IonInput,
    IonButton
  ],
  selector: 'app-descripcion-paquetes',
  templateUrl: './descripcion-paquetes.component.html',
  styleUrls: ['./descripcion-paquetes.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
  providers: [
    ServiciowishlistService, ApiProductosService, CarritoServiceService, LoginService
  ],
})
export class DescripcionPaquetesComponent implements OnInit {
  id_product: any;
  public descripcion: any;

  categoriaadfdf: any = []
  productoData: any;
  relacionados: any = [];
  public colorSeleccionado: string = '';
  atributos: any;
  public precioDyn: any;
  public color = 'blanco';
  items: any = [];
  referenciaChida: any;
  public totalItems: number = 0;
  public crr_sel = 0;
  public crr_indx = 0;
  public cantidad: number = 1;
  public existencias: any;
  usuario!: Usuario;
  productoSKU: any;
  unidaddeVenta: any;
  descripcionsita: any;
  descripcionsillas: any = [];
  descripcionsilla: any;
  slider: any = [];
  paquetaxos: any = [];
  contenidoHTML: any;
  existencia: any;
  public baseUrl: string = 'https://new.lideart.com.mx/';
  cargando = false;
  resdesc: any = [];
  descuentillo = false;
  public fbqCategory: any;
  descuento: any;
  parametros: any = [];
  estoyactivo: any;
  email: any;
  varColorSelected: any;

  public getUrl: any;

  public infoUser: any = [];
  public infoUser2: any = [];

  public imgUrlGoogle: any = '';
  precioFacebok: any;

  constructor(
    private productoService: ApiProductosService,
    public toastController: ToastController,
    private shoppingCart: CarritoServiceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private servicioWishlist: ServiciowishlistService,
    private location: Location,
    private titleService: Title,

    private metaService: Meta,
    private usuarioService: LoginService,
    private renderer: Renderer2,
    private satanizador: DomSanitizer,
    private apiFacebook: FacebookApiService,
    private checkoutService: CheckoutServiceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof KueskipayAdvertising !== 'undefined') {
        new KueskipayAdvertising().init();
      } else {
        console.error('KueskipayAdvertising no está definida');
      }

      this.usuarioService.usuario.subscribe((res: any) => {
        this.usuario = res;
        if (this.usuario) {
          this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
            this.infoUser = data;
            //console.log(this.infoUser);

            this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
              this.infoUser2 = res;
              //console.log(this.infoUser2);
            });
          });
        }


      });

    }
    this.cargando = true;
    /*if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }*/
    this.activatedRoute.params.subscribe(params => {
      this.getUrl = params['id_product-:sku'];
      this.parametros = params['id_product-:sku'].split('-');
      this.id_product = params['id_product-:sku'].split('-');
      this.fbqCategory = params['category'];
      this.id_product = this.id_product[0];
      // this.id_product = params['id_productP'];
      //this.getDescripcionProducto();
    })
    this.shoppingCart.cartItems.subscribe(d => {
      if (d && Array.isArray(d)) {
        this.totalItems = d.length;
      } else {
        this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
      }
    });

    this.getDescripciones();

    // ESTO ES LO QUE ESTABA EN EL METODO getDescripcionProducto()
    this.productoService.getDescripcionPaquete(this.id_product).subscribe(data => {
      this.productoData = data;
      ////console.log(this.productoData);
      this.atributos = this.productoData[0].detalles.atributos;
      this.precioDyn = this.productoData[0].precio;
      const metaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTag, 'name', 'product:price');
      this.renderer.setAttribute(metaTag, 'content', this.precioDyn);

      this.renderer.appendChild(document.head, metaTag);
      this.precioFacebok = this.precioDyn;
      // this.descripcionsita = this.productoData[0].description;
      // this.descripcionsilla = this.productoData[0].description_short;

      this.estoyactivo = this.productoData[0].activo;

      if (this.imgUrlGoogle === '') {
        this.productoService.getSliderImgs(this.id_product).subscribe((datazos: any) => {
          this.imgUrlGoogle = datazos[0].slider;
          //console.log(this.imgUrlGoogle, this.precioDyn, this.productoData[0].title);
          /*const script = this.renderer.createElement('script');     
          script.type = 'application/ld+json';     
          script.text = JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.productoData[0].title,
            "image": this.imgUrlGoogle,
            "offers": {
              "@type": "Offer",
              "priceCurrency": "MXN",
              "price": this.precioDyn,
              "availability": "https://schema.org/InStock"
            }
          }); 
          this.renderer.appendChild(document.head, script);*/
        });
      }

      this.productoService.getCategoriaItem(this.id_product).subscribe((fdsfdfsdfsdf: any) => {
        this.categoriaadfdf = fdsfdfsdfsdf

        let id_category = this.categoriaadfdf[0].id_category


        this.productoService.getRelacionados(id_category).subscribe((productillos: any) => {
          try {
            this.relacionados = productillos;
          } catch (error) {
            this.relacionados = [];
          }

        })

      })



      if (this.descripcionsita) {
        this.contenidoHTML = this.satanizador.bypassSecurityTrustHtml(this.descripcionsita.replace(/src="\/(.*?)"/g, 'src="' + this.baseUrl + '$1"'));
      } else {
        console.warn('descripcionsita está indefinido');
        this.contenidoHTML = this.satanizador.bypassSecurityTrustHtml('');
      }
      this.productoSKU = this.productoData[0].sku;
      var keywords = this.productoData[0].tags
      if (this.id_product == 6530) {
        this.titleService.setTitle('| Paquete para crear Caja de Regalo |');
      } else if (this.getUrl.includes('5837-paquete-laminadora-minc-12-pulgadas.html')) {
        this.titleService.setTitle('| Paquete Laminadora Minc 12 pulgadas |');
      } else if (this.getUrl.includes('6296-PINM-0001.html')) {
        this.titleService.setTitle('| Paquete Fotobotonera Pin Maker Plus |');
      } else if (this.getUrl.includes('6698-SAWG-0009.html')) {
        this.titleService.setTitle('| Paquete Sublimación de Tazas Sawgrass SG500 + Cricut Mugpress |');
      } else if (this.getUrl.includes('5489-kit-estudio-de-fotografía-emprendedor.html')) {
        this.titleService.setTitle('| Kit Estudio de Fotografía Emprendedor |');
      } else if (this.getUrl.includes('5485-COST-0002.html')) {
        this.titleService.setTitle('| Kit de Costura para Principiantes |');
      } else if (this.id_product == 5479) {
        this.titleService.setTitle('| Paquete Plotter de Corte Silhouette Portrait 3 Decoración Textil |');
      } else if (this.getUrl.includes('5871-ESMR-0003.html')) {
        this.titleService.setTitle('| Paquete Pasta para esmerilar |');
      } else if (this.id_product == 5478) {
        this.titleService.setTitle('| Paquete Plotter de Corte Silhouette Portrait 3 Plus |');
      } else if (this.id_product == 6292) {
        this.titleService.setTitle('| Paquete Silhouette Lovers |');
      } else if (this.getUrl.includes('5475-SILH-0018.html')) {
        this.titleService.setTitle('| Paquete Impresora 3D Silhouette Alta Plus |');
      } else if (this.id_product == 6659) {
        this.titleService.setTitle('| Kit Completo Navajas Cricut Maker |');
      } else if (this.id_product == 6667) {
        this.titleService.setTitle('| Combo Plotter de Corte Silhouette Cameo 4 blanco rotulacion |');
      } else if (this.id_product == 6354) {
        this.titleService.setTitle('| DUO MINC MINI Silhouette Portrait 3 + Laminadora Minc 6" |');
      } else if (this.getUrl.includes('6529-CRIC-0027.html')) {
        this.titleService.setTitle('| Paquete Joy Smart |');
      } else if (this.id_product == 6699) {
        this.titleService.setTitle('| Combo Plotter de Corte Silhouette cameo 5 rotulación |');
      } else if (this.id_product == 6701) {
        this.titleService.setTitle('| DUO Silhouette Cameo 5 Color Blanco + Laminadora Foil |');
      } else if (this.getUrl.includes('5207-CRIC-0020.html')) {
        this.titleService.setTitle('| Paquete Cricut Maker 3 Smart |');
      } else if (this.getUrl.includes('6651-LUST-0002.html')) {
        this.titleService.setTitle('| Paquete Pistola de Calor Luster Embossing Mint |');
      } else if (this.id_product == 5490) {
        this.titleService.setTitle('| Paquete Silhouette Cameo 4 Stickers |');
      } else if (this.id_product == 5832) {
        this.titleService.setTitle('| Paquete Explore Air 2 Lila |');
      } else if (this.getUrl.includes('6664-LUMN-0003.html')) {
        this.titleService.setTitle('| Paquete Papel Foil Luminos Winterland |');
      } else if (this.id_product == 6286) {
        this.titleService.setTitle('| Paquete de Ojilladora Crop-a-Dile Wer 1/8" y 3/16" con surtido de colores de ojillos |');
      } else if (this.id_product == 6700) {
        this.titleService.setTitle('| Paquete Hot Stamping con Silhouette Cameo 5 |');
      } else if (this.id_product == 5488) {
        this.titleService.setTitle('| Kit para Arte en Acuarela |');
      } else if (this.id_product == 5839) {
        this.titleService.setTitle('| Paquete Silhouette Cameo 4 B&W |');
      } else if (this.id_product == 6528) {
        this.titleService.setTitle('| DUO Silhouette Cameo 4 Color Blanco + Laminadora Pink 13" |');
      } else if (this.getUrl.includes('5210-CRIC-0023.html')) {
        this.titleService.setTitle('| Paquete Cricut Explore 3 Completo |');
      } else if (this.getUrl.includes('5383-SAWG-0006.html')) {
        this.titleService.setTitle('| Paquete Sublimación Plus Sawgrass SG500 |');
      } else if (this.getUrl.includes('6036-4425-0111.html')) {
        this.titleService.setTitle('| Paquete Plotter Cricut Maker DIY |');
      } else if (this.getUrl.includes('5382-SAWG-0004.html')) {
        this.titleService.setTitle('| Paquete Impresora Sawgrass SG1000 + Kit de Inicio Tintas Sublijet |');
      } else if (this.getUrl.includes('6343-SILH-0037.html')) {
        this.titleService.setTitle('| Paquete Rotulación Silhouette Cameo 4 Pro |');
      } else if (this.id_product == 6295) {
        this.titleService.setTitle('| Paquete para encuadernado Cinch disc WeR |');
      } else if (this.id_product == 6290) {
        this.titleService.setTitle('| Paquete Cameo 4 Empire Red + Laminadora Luminos y Viniles |');
      } else if (this.id_product == 5476) {
        this.titleService.setTitle('| Paquete viniles de corte Oracal 641 24" |');
      } else if (this.getUrl.includes('6666-paquete-5-rollos-de-foil-luminos-christmas-gold.html')) {
        this.titleService.setTitle('| Paquete 5 rollos de Foil Luminos Christmas Gold |');
      } else if (this.getUrl.includes('6302-CRIC-0025.html')) {
        this.titleService.setTitle('| Paquete explore air 2 boysenberry |');
      } else if (this.getUrl.includes('6650-LUST-0001.html')) {
        this.titleService.setTitle('| Paquete Pistola de Calor Luster Embossing Pink |');
      } else if (this.getUrl.includes('6294-CNCH-0002.html')) {
        this.titleService.setTitle('| Paquete Para Encuadernado Cinch Max |');
      } else if (this.id_product == 6287) {
        this.titleService.setTitle('| Paquete Cameo Plus 15" y Laminadora Luminos y Viniles |');
      } else if (this.getUrl.includes('6855-SILH-0033-RED.html')) {
        this.titleService.setTitle('| Paquete Silhouette Lovers Rojo |');
      } else if (this.getUrl.includes('6289-SILH-0031.html')) {
        this.titleService.setTitle('| Paquete Cameo Pro 24" + Laminadora Luminos y Viniles |');
      } else if (this.getUrl.includes('5209-CRIC-0022.html')) {
        this.titleService.setTitle('| Paquete Cricut Explore 3 Smart |');
      } else if (this.id_product == 5486) {
        this.titleService.setTitle('| Paquete Colección Vintage Heidi Swapp |');
      } else if (this.id_product == 5480) {
        this.titleService.setTitle('| Paquete Vinil de Corte Signcal 30 pies |');
      } else if (this.getUrl.includes('6852-SILH-0001-BLK.html')) {
        this.titleService.setTitle('| Paquete Silhouette Cameo 4 Negro + Laminadora |');
      } else if (this.id_product == 6673) {
        this.titleService.setTitle('| Combo Plotter de Corte Silhouette Portrait 3 rotulacion |');
      } else if (this.getUrl.includes('5381-SAWG-0007.html')) {
        this.titleService.setTitle('| Paquete Sublimación Tazas Sawgrass SG500 |');
      } else if (this.id_product == 6352) {
        this.titleService.setTitle('| DUO BLANCO MINC Silhouette Cameo 4 + Laminadora 12" |');
      } else if (this.getUrl.includes('6658-CRIC-0029.html')) {
        this.titleService.setTitle('| Kit Completo Navajas Cricut Maker Con Organizador |');
      } else if (this.id_product == 6668) {
        this.titleService.setTitle('| Combo Plotter de Corte Silhouette Cameo 4 Rojo rotulacion |');
      } else if (this.getUrl.includes('6856-SILH-0033-BLK.html')) {
        this.titleService.setTitle('| Paquete Silhouette Lovers Negro |');
      } else if (this.getUrl.includes('6854-SILH-0003-BLK.html')) {
        this.titleService.setTitle('| DUO Silhouette Cameo 4 Negro + Laminadora Foil |');
      } else if (this.getUrl.includes('5208-CRIC-0021.html')) {
        this.titleService.setTitle('| Paquete Cricut Maker 3 Sistema Completo |');
      } else if (this.getUrl.includes('6851-SILH-0001-RED.html')) {
        this.titleService.setTitle('| Paquete Silhouette Cameo 4 Rojo + Laminadora |');
      } else if (this.id_product == 6670) {
        this.titleService.setTitle('| Combo Plotter de Corte Silhouette Cameo 4 Negro rotulacion |');
      } else if (this.getUrl.includes('5226-RESN-0001.html')) {
        this.titleService.setTitle('| Paquete Resina para decoración y manualidades Color Pour |');
      } else if (this.id_product == 5869) {
        this.titleService.setTitle('| Paquete Plotter de Corte Silhouette Portrait 3 + Laminadora 13" |');
      } else if (this.id_product == 6527) {
        this.titleService.setTitle('| DUO Silhouette Cameo 4 Color Blanco + Laminadora Pink 9" |');
      } else if (this.getUrl.includes('6665-LUMN-0002.html')) {
        this.titleService.setTitle('| Paquete 5 rollos de Foil Luminos Pumpking Shine |');
      } else if (this.id_product == 5491) {
        this.titleService.setTitle('| Paquete Silhouette Cameo 4 Designer Edition |');
      } else if (this.getUrl.includes('6853-SILH-0003-RED.html')) {
        this.titleService.setTitle('| DUO Silhouette Cameo 4 Rojo + Laminadora Foil |');
      } else {
        this.titleService.setTitle(this.productoData[0].title);
      }
      if (this.productoData[0].description_short === '' || this.productoData[0].description_short === null || this.productoData[0].description_short === undefined || this.productoData[0].description_short === "undefined") {
        this.metaService.addTag({ name: 'description', content: this.productoData[0].title });
      } else if (this.productoData[0].description_short != '' || this.productoData[0].description_short != null || this.productoData[0].description_short != undefined || this.productoData[0].description_short != "undefined") {
        this.metaService.addTag({ name: 'description', content: this.productoData[0].description_short });
      } else {
        this.metaService.addTag({ name: 'description', content: this.productoData[0].title });
      }
      this.metaService.addTag({ name: 'keywords', content: keywords });
      this.colorDefault()

      /*if (this.usuario) {
        setTimeout(() => {
          this.apiFacebook.sendEvent('ViewContent', this.precioFacebok.toFixed(2), this.usuario.usuario, this.infoUser2[0].phone, {
            external_id: this.infoUser.customerID,
            nombre: this.infoUser2[0].firstname,
            apellido: this.infoUser2[0].lastname,
            estado: this.infoUser2[0].id_state,
            ciudad: this.infoUser2[0].city,
            country: 'México',
            codigo_postal: this.infoUser2[0].postcode,
            num_items: 1,
            content_type: 'producto',
            content_name: this.productoData[0].title,
            content_ids: [this.id_product]
          });
        }, 3000);
      }*/



      var jsondescuento = {
        "descuento": this.id_product
      }

      this.productoService.getDescuento(jsondescuento).subscribe(resdesc => {
        this.resdesc = resdesc;

        try {
          if (this.resdesc === 1) {

            this.descuentillo = false;
            this.descuento = 1;
          } else {

            this.descuentillo = true;
            this.descuento = this.resdesc;
            this.descuento = 1 - this.descuento
          }
        } catch (error) {

          this.descuentillo = false;
          this.descuento = this.resdesc;
          this.descuento = 1 - this.descuento
        }

        this.productoService.getSliderImgs(this.id_product).subscribe(datazos => {
          this.slider = datazos;

          this.productoService.getInfoPaquetes(this.productoSKU).subscribe(paquetaxo => {
            this.paquetaxos = paquetaxo;

            let array = []
            for (let index = 0; index < this.paquetaxos.length; index++) {
              if (this.paquetaxos[index].principal == 1) {
                const element = this.paquetaxos[index].inventario;
                array.push(element);
              }
            }

            const min = Math.min(...array);
            const bajo = min / 2;
            const masbajo = Math.floor(bajo)
            this.existencias = masbajo;

            if (this.existencias == Infinity) {
              this.existencias = 0;
            }
            this.cargando = false;
          })
        })
      })
    })
    // ESTO ES LO QUE ESTABA EN EL METODO getDescripcionProducto()
  }

  goBack() {
    this.location.back();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Articulo Agregado a Carrito',
      color: "success",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  getDescripcionProducto() {

  }

  colorDefault() {
    setTimeout(() => {

      try {
        let span = document.getElementsByClassName('id_group_2 selector-color')[0].children[0] as HTMLElement;
        if (span) {
          span.click();
        }
      } catch (error) {

      }

      try {
        let span2 = document.getElementsByClassName('id_group_7 selector-color')[0].children[0] as HTMLElement;
        if (span2) {
          span2.click();
        }
      } catch (error) {

      }
    }, 1000);
  }


  getDescripciones() {
    this.productoService.getDescripciones(this.id_product).subscribe(data => {
      this.descripcionsillas = data;
      try {
        this.descripcionsita = this.descripcionsillas[0].description;
        this.descripcionsilla = this.descripcionsillas[0].description_short;


        this.contenidoHTML = this.satanizador.bypassSecurityTrustHtml(this.descripcionsita.replace(/src="\/(.*?)"/g, 'src="' + this.baseUrl + '$1"'));
      } catch (error) {
        this.descripcionsita = "";
        this.descripcionsilla = "";
      }
    })
  }

  agregarAlCarrito(producto: Producto) {
    // producto.precio = this.productoData.precio;
    if (this.productoSKU == 'PREV-4425-0007') {
      producto.sku = '4425-0007'
    }
    producto.existencia = this.existencias;
    producto.color = this.color;
    producto.category_rewrite = this.fbqCategory
    producto.link_rewrite = this.productoData[0].link_rewrite;
    if (producto.color == "") {

      this.ShowError2();

    } else {

      producto.quantity = this.cantidad;

      if (this.existencias < this.cantidad) {

        this.ShowError();

      } else {
        let pasa = true;
        this.shoppingCart.cartItems.subscribe(d => {
          this.items = d;
          this.totalItems = d.length;
        });

        if (this.totalItems > 0) {
          if (this.items && Array.isArray(this.items)) {
            for (let index = 0; index < this.items.length; index++) {
              const element = this.items[index];
              if (producto.sku == element.sku) {
                if (this.existencias < (element.quantity + producto.quantity)) {
                  this.ShowError3();
                  pasa = false;
                }
              }
            }
          } else {
            console.warn('items está indefinido o no es un array');
            // Manejar el caso en que items es indefinido o no es un array
          }
        }
        if (pasa) {
          this.showSuccess();
          if (this.descuento == 0) {
            if (this.usuario) {
              this.email = this.usuario.usuario
            } else {
              this.email = "";
            }
            if (this.totalItems < 1) {

              let datos = {
                "email": this.email
              }
              this.shoppingCart.registraNuevoCarrito(datos).subscribe((data: any) => {

              })

            }

            if (this.productoSKU == 'PREV-4425-0007') {
              producto.sku = '4425-0007'
            }
            producto.description_short = "";
            producto.nombreMuestra = this.productoData[0].title
            producto.title = this.productoData[0].title
            producto.paquete = 1;
            producto.precio = this.productoData[0].precio
            this.shoppingCart.addItem(producto);
            if (this.usuario) {
              this.apiFacebook.sendEvent('AddToCart', this.precioFacebok, this.usuario.usuario, this.infoUser2[0].phone, {
                external_id: this.infoUser.customerID,
                nombre: this.infoUser2[0].firstname,
                apellido: this.infoUser2[0].lastname,
                estado: this.infoUser2[0].id_state,
                ciudad: this.infoUser2[0].city,
                country: 'México',
                codigo_postal: this.infoUser2[0].postcode
              });
            }

            setTimeout(() => {
              window.location.reload()
            }, 2000);

          }
          if (this.descuento > 0) {

            if (this.usuario) {
              this.email = this.usuario.usuario
            } else {
              this.email = "";
            }
            if (this.totalItems < 1) {

              let datos = {
                "email": this.email
              }
              this.shoppingCart.registraNuevoCarrito(datos).subscribe((data: any) => {
              })

            }

            if (this.productoSKU == 'PREV-4425-0007') {
              producto.sku = '4425-0007'
            }
            producto.nombreMuestra = this.productoData[0].title
            producto.title = this.productoData[0].title
            producto.paquete = 1;
            producto.precio = this.productoData[0].precio * this.descuento
            this.shoppingCart.addItem(producto);
            if (this.usuario) {
              this.apiFacebook.sendEvent('AddToCart', this.precioFacebok, this.usuario.usuario, this.infoUser2[0].phone, {
                external_id: this.infoUser.customerID,
                nombre: this.infoUser2[0].firstname,
                apellido: this.infoUser2[0].lastname,
                estado: this.infoUser2[0].id_state,
                ciudad: this.infoUser2[0].city,
                country: 'México',
                codigo_postal: this.infoUser2[0].postcode
              });
            }
            setTimeout(() => {
              window.location.reload()
            }, 2000);
          }
        }
      }

    }
  }


  navigateToDescripcionProducto(c: any, b: any, a: any, d: any, e: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    if (d == 1) {
      this.router.navigateByUrl(`/paquetes/${id_product}-${sku}` + ".html");
    } else {
      this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
    }
  }

  seleccionacolor(nombreColor: any, id_attribute: number, id_group: number, colvar: any) {
    this.varColorSelected = colvar;
    this.colorSeleccionado = nombreColor;
    if (document.getElementsByClassName('selected selector-color').length > 0) {
      document.getElementsByClassName('selected selector-color')[0].className =
        document
          .getElementsByClassName('selected selector-color')[0]
          .className.replace('selected', '');
    }
    var cuadro = document.getElementById('' + id_attribute);
    if (cuadro) cuadro.className = 'selected ' + cuadro.className;

    this.cambiaSku();
    this.color = document.getElementsByClassName(
      'selected selector-color'
    )[0].className;
    let colorsito = document.getElementsByClassName('selected');
    if (colorsito.length > 0) {
      this.color = '' + colorsito[0].children[0].getAttribute('title');
    }
  }
  cambiaSku() {
    var ladata = this.productoData[0];
    var variables = ladata.detalles.variables;
    if (document.getElementsByClassName('selected selector-color').length > 0) {
      var selecciones = [];
      selecciones.push({
        id_group: document
          .getElementsByClassName('selected selector-color')[0]
          .className.split('id_group_')[1]
          .split(' ')[0],
        id_attribute: document.getElementsByClassName(
          'selected selector-color'
        )[0].id,
      });
      if (document.getElementsByName('atributos').length > 0) {
        var selectores = document.getElementsByName('atributos');
        for (var i = 0; i < selectores.length; i++) {
          var sl = selectores[i];
          selecciones.push({
            id_group: sl.classList[0],
            id_attribute: (<HTMLInputElement>sl).value,
          });
        }
      }
      var pasa = true;

      for (var i = 0; i < variables.length; i++) {
        pasa = false
        var nkeys = Object.keys(variables[i].atributos)
        var aciertos = 0
        var nvalor = 0

        for (var j = 0; j < nkeys.length; j++) {
          for (var k = 0; k < selecciones.length; k++) {
            if (selecciones[k].id_group == nkeys[j]) {
              if (variables[i].atributos[selecciones[k].id_group] == selecciones[k].id_attribute) {
                aciertos++
              }
            }
          }
        }
        if (aciertos == nkeys.length) {

          pasa = true
          nvalor = i
          i = variables.length + 10
        }

        if (pasa) {


          ladata.sku = variables[nvalor].reference;


          var lrf = document.getElementById('referencia');
          var uv = document.getElementById('unidadVenta');
          if (lrf) {
            lrf.innerText = variables[nvalor].reference.replace(/-R$/, '');
            var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
            referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
            this.referenciaChida = referenciaLimpia;

            // this.getExistencias(referenciaLimpia,sitio);

            if (uv) {
              uv.innerText = variables[nvalor].unidadVenta.replace(/-R$/, '');
              var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];

              // this.getPrecioUnitario(referenciaLimpia,unidadventLimpia);
            }
          }
          i = variables.length + 100;
          this.crr_sel = 0;
          this.crr_indx = 0;
        }
      }
      if (!pasa) {
        if (
          this.crr_sel <=
          document.getElementsByName('atributos').length - 1
        ) {
          var elselect = document.getElementsByName('atributos')[this.crr_sel];
          if (
            (<HTMLSelectElement>elselect).options.length >
            this.crr_indx + 1
          ) {
            (<HTMLSelectElement>elselect).selectedIndex = this.crr_indx;
            this.crr_indx++;
          } else {
            this.crr_sel++;
            this.crr_indx = 0;
          }
          this.cambiaSku();
          var lrf = document.getElementById('referencia');
          var uv = document.getElementById('unidadVenta');
          if (lrf) {
            lrf.innerText = variables[i].reference.replace(/-R$/, '');
            var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
            referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
            this.referenciaChida = referenciaLimpia;

            // this.getExistencias(referenciaLimpia,sitio);

            if (uv) {
              uv.innerText = variables[i].unidadVenta.replace(/-R$/, '');
              var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];

              // this.getPrecioUnitario(referenciaLimpia,unidadventLimpia);
            }
          }


        } else {
          this.crr_sel = 0;
          this.crr_indx = 0;
        }
      }
    }
  }

  async ShowError() {
    const toast = await this.toastController.create({
      message: 'Inventario Insuficiente',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async ShowError3() {
    const toast = await this.toastController.create({
      message: 'No puedes llevar al Carrito mas de la Cantidad en Existencias',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async showSuccess() {

    // const toast = await this.toastController.create({
    //   message: 'Producto agregado al carrito',
    //   color: "success",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Exito!",
      text: "Producto Agregado a Carrito",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });


  }

  async ShowError2() {
    const toast = await this.toastController.create({
      message: 'Necesitas Seleccionar Color',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async yaexiste() {
    const toast = await this.toastController.create({
      message: 'Este Producto ya se encuentra en tu Wishlist.',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async agregado() {
    const toast = await this.toastController.create({
      message: 'Este Producto se agrego a tu Wishlist.',
      color: "success",
      position: "top",
      duration: 1500

    });
    toast.present();
  }


  agregarWishlist() {
    var jsonWishlist = {
      "usuario": this.usuario.usuario,
      "id_product": this.productoData[0].id_product,
      "title": this.productoData[0].title,
      "sku": this.productoData[0].sku,
      "quantiity": this.cantidad,
      "imagen": this.productoData[0].image_link,
      "sku_imagen": this.referenciaChida,
      "color": this.colorSeleccionado,
      "medida": this.productoData[0].unitId,
      precio: this.precioDyn
    }
    this.servicioWishlist.sendWishlist(jsonWishlist).subscribe(data => {
      if (data == 0) {
        this.yaexiste();
      } else {
        /*if (this.usuario) {
          this.apiFacebook.sendEvent('AddToWishlist', this.precioFacebok.toFixed(2), this.usuario.usuario, this.infoUser2[0].phone, {
            external_id: this.infoUser.customerID,
            nombre: this.infoUser2[0].firstname,
            apellido: this.infoUser2[0].lastname,
            estado: this.infoUser2[0].id_state,
            ciudad: this.infoUser2[0].city,
            country: 'México',
            codigo_postal: this.infoUser2[0].postcode
          });
        }*/

        this.agregado();
      }
    })

  }
}
